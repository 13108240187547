@import "abstracts/mixins";
@import "abstracts/variables";

section {
  flex-direction: row;

  @include from-size($mojo-breakpoint-md-plus) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &.two-third-left {
    grid-template-columns: 2fr 1fr;
  }
  &.two-third-right {
    grid-template-columns: 1fr 2fr;
  }

  .left-column {
    margin-bottom: $spacing-v2-32;
    @include from-size($mojo-breakpoint-md-plus) {
      margin-bottom: 0;
    }
  }
}
