@import "abstracts/variables";

.breadcrumb-item {
  &::after {
    content: "/";
    margin: 0 $spacing-8;
  }

  &:last-child::after {
    content: "";
    margin: 0;
  }
}
