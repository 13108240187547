@import "abstracts/variables";
@import "v2/typography";
@import "v2/mixins";

.free-consultation {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacing-v2-48;

  @include from-size($mojo-breakpoint-md-plus) {
    flex-direction: row;
  }

  app-icon-with-text {
    margin-bottom: $spacing-v2-16;
  }
}

.teaser {
  .right-column {
    display: flex;

    @include from-size($mojo-breakpoint-md-plus) {
      justify-content: flex-end;
    }
  }
}

.monthly-subscription {
  .left-column {
    align-self: center;

    @include from-size($mojo-breakpoint-md-plus) {
      width: 80%;
    }
  }
}
