@import "abstracts/variables";
@import "abstracts/mixins";
@import "v2/mixins";

.link {
  display: inline-flex;
  align-items: center;
  color: inherit;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    .content.action-border {
      &::after {
        width: 100%;
      }
    }
  }

  &.semibold {
    @include typography-text-3--semibold();
  }

  &.medium {
    @include typography-text-3--medium();
  }

  &.blue {
    color: $renovero-blue;

    .content {
      @include action-border($renovero-blue);
    }
  }

  &.white {
    color: $renovero-white;

    .content {
      @include action-border($renovero-white);
    }
  }

  &.hide-before {
    .content {
      &::before {
        display: none;
      }
    }
  }

  .icon {
    display: inline-flex;
    width: $spacing-v2-10;
    height: $spacing-v2-10;
    align-items: center;
    justify-content: center;
    margin-left: $spacing-v2-7;
  }
}
