@import "abstracts/variables";
@import "abstracts/mixins";

.rich-text {
  margin: 0;

  ul,
  .list {
    list-style-type: none;

    .list-bullet {
      font-size: 12px;
      margin-right: 8px;
      float: left;
      color: $renovero-yellow;
      &.bullet-color-blue {
        color: $renovero-blue;
      }
    }
  }

  .orderedList {
    padding-left: 20px;
    li::marker {
      color: $renovero-yellow;
    }
    li.bullet-color-blue::marker {
      color: $renovero-blue;
    }
  }
}
.mb-64 {
  margin-bottom: $spacing-32;

  @include from-size-md() {
    margin-bottom: $spacing-64;
  }
}
.mb-32 {
  margin-bottom: $spacing-16;

  @include from-size-md() {
    margin-bottom: $spacing-32;
  }
}
