@import "abstracts/variables";
@import "abstracts/mixins";
@import "v2/mixins";

.footer {
  &-container {
    .footer-content {
      max-width: $container-size-v2;
      display: flex;
      flex-direction: column;
      margin: 0 auto;

      @include from-size-md-plus() {
        flex-direction: row;
        justify-content: space-between;
      }
    }

    &--top {
      background-color: $renovero-blue;
      color: $renovero-white;
      padding: $spacing-v2-40 $spacing-v2-16;

      @include from-size-md-plus() {
        padding-top: $spacing-v2-60;
        padding-bottom: $spacing-v2-60;
      }

      .footer-content {
        @include from-size-md-plus() {
          flex-direction: row;
          justify-content: space-between;
        }

        .column {
          padding: 0 $spacing-v2-8;

          &--top-left {
            &:not(:last-of-type) {
              margin-bottom: $spacing-v2-26;
            }

            @include from-size-lg-v2() {
              margin-bottom: 0;
            }

            .logo-container {
              &:not(:last-of-type) {
                margin-bottom: $spacing-v2-26;
              }

              & > a {
                display: flex;
              }

              &__img {
                width: 140px;

                @include from-size-md-plus {
                  width: 200px;
                }
              }
            }
          }

          .navigation-container {
            display: flex;
            flex-direction: column;
            border-bottom: 1px solid $renovero-v2-blue-dark;
            height: $spacing-v2-60;
            overflow: hidden;

            @include from-size-md-plus() {
              border-bottom: none;
              overflow: initial;
              height: auto;
            }

            &--expanded {
              overflow: initial;
              height: auto;

              & .title-container__icon {
                transform: rotate(180deg);
              }
            }

            .title-container {
              display: flex;
              justify-content: space-between;
              padding: $spacing-v2-20 0;

              @include from-size-md-plus() {
                padding: 0;
                margin-bottom: $spacing-v2-20;
                cursor: initial;
              }

              &__title {
                @include typography-text-4--extrabold-uppercase();
                margin: 0;
              }

              &__icon {
                transition: 0.3s;

                @include from-size-md-plus() {
                  display: none;
                }
              }
            }

            .navigation {
              display: flex;
              flex-direction: column;
              gap: $spacing-v2-16;
              padding-bottom: $spacing-v2-20;

              @include from-size-md-plus() {
                padding-bottom: 0;
                gap: $spacing-v2-12;
              }

              &__item {
                position: relative;
                align-self: flex-start;
                cursor: pointer;
                @include typography-text-1();

                @include from-size-md-plus() {
                  @include typography-text-5--medium();
                }
              }

              &__link {
                color: $renovero-white;
                text-decoration: none;
                position: relative;

                &:hover {
                  &:after {
                    content: "";
                    width: 100%;
                    height: 1px;
                    background-color: $renovero-yellow;
                    position: absolute;
                    top: calc(100% + $spacing-v2-4);
                    left: 0;
                  }
                }
              }
            }
          }

          .contact-container {
            display: flex;
            flex-direction: column;
            gap: $spacing-v2-4;
            margin-top: $spacing-v2-20;

            @include from-size-md-plus() {
              margin-top: $spacing-v2-30;
            }

            &__item {
              @include typography-text-2--bold();
              font-size: $font-v2-size-16;

              @include from-size-md-plus() {
                font-size: $font-v2-size-14;
              }

              & > a {
                text-decoration: none;
                color: $renovero-white;
              }
            }
          }

          .social-container {
            display: flex;
            gap: $spacing-v2-8;
            margin-top: $spacing-v2-30;

            &__item {
              display: flex;
              align-items: center;
              justify-content: center;
              width: $footer-social-icon-size-mobile;
              height: $footer-social-icon-size-mobile;
              background-color: $renovero-yellow;
              color: $renovero-blue;
              cursor: pointer;
              font-size: $font-v2-size-22;

              &:hover {
                background-color: $renovero-v2-secondary-light-yellow;
              }

              @include from-size-md-plus() {
                width: $footer-social-icon-size-desktop;
                height: $footer-social-icon-size-desktop;
                font-size: $font-v2-size-18;
              }
            }
          }
        }
      }
    }

    &--bottom {
      background-color: $renovero-yellow;

      .footer-content {
        padding: $spacing-v2-20 $spacing-v2-16;

        @include from-size-md-plus() {
          padding: $spacing-v2-20 $spacing-v2-24;
        }
      }

      .column {
        color: $renovero-blue;

        &--bottom-left {
          display: flex;
          flex-direction: column;
          margin-bottom: $spacing-v2-16;
          gap: $spacing-v2-16;

          @include from-size-md-plus() {
            margin-bottom: 0;
          }

          @include from-size-lg() {
            flex-direction: row;
          }
        }
      }

      .copyright {
        margin-bottom: $spacing-v2-16;
        @include typography-text-5--medium();

        @include from-size-lg() {
          margin-bottom: 0;
          margin-right: $spacing-v2-16;
        }
      }

      .navigation {
        display: flex;
        flex-direction: column;
        gap: $spacing-v2-16;

        @include from-size-md-plus() {
          flex-direction: row;
        }

        &__item {
          & a {
            @include typography-text-5--medium();
            color: $renovero-blue;
            text-decoration: none;
          }
        }
      }

      .languages {
        display: flex;
        gap: $spacing-v2-22;

        &__item {
          position: relative;
          @include typography-text-5--medium();

          & > a {
            color: $renovero-blue;
            text-decoration: none;
          }

          &--active {
            font-weight: $renovero-font-bold;
          }

          &:not(:last-child) {
            &:after {
              content: "•";
              margin-left: $spacing-v2-8;
              position: absolute;
              left: 100%;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
      }
    }
  }
}
